.multiSelect-form{    
    display: flex;
    margin-top: 15px;
    max-width: 75vw;
    width: 28em;
}

.widget-dialog .multiSelect-form{    
    max-width: 244px;
} 

.multiSelect-form__submit{
    width: 42px !important;
    height: auto!important;
    border-radius: 0 8% 8% 0 !important;
    min-width: 42px !important;
}

.MuiAutocomplete-popper{
    display: flex !important;
    justify-content: flex-start !important;  
    inset: none !important;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-4lnqr8-MuiAutocomplete-root .MuiOutlinedInput-root{
    padding-right: 0px !important;
}