.powered-by{
    position: relative;
    padding: 0 10px 10px 10px;
    margin-bottom: 10px;
    opacity: 0.6;
}

.powered-by span{
    font-size: 0.4em;
    position: absolute;
    top: 9px;
    right: 51%;
}

.powered-by img{
    position: relative;
    float: right;
    padding-bottom: 10px;
}

