.containerButtons {
  height: 45px;
}

.chatbothr_containerbuttons {
  border: 1px solid #eeeeee;
  border-radius: 0px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  height: 45px;
  position: relative;
  width: 100%;
  z-index: 1600;
}

section{
  padding: 0 0 !important;
}

@media  (max-width: 991px){
  section{
    padding: 0 0 !important;
  }
}

@media (max-width: 767px){  
  section{
    padding: 0 0 !important;
  }
}

@media screen and (max-width: 767px){  
  section{
    padding: 0 0 !important;
  }
}

