.typing {
  background: #ffffff;
  border-radius: 5px;
  height: 2em;;
  margin-left: 5px;
  padding: 10px;
  position: relative;
  width: 5em;
}
  
.typing__dot {
  animation: loadingFade 1s infinite;
  background: #8d8c91;
  border-radius: 50%;
  height: 8px;
  margin: 0 4px;
  opacity: 0;
  width: 8px;
}
  
.typing__dot:nth-child(1) {
  animation-delay: 0s;
}

.typing__dot:nth-child(2) {
  animation-delay: 0.2s;
}

.typing__dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loadingFade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}