.widget-dialog__content {
  all: initial;
}

.widget-dialog__content::-webkit-scrollbar {
  width: 0!important;
}

.widget-dialog{
  height: 0;
  width: 0;
}

.widget-dialog .MuiModal-backdrop{
  display: none !important;
}

.widget-dialog__content{
  position: fixed;
  bottom:-7px;
  right: 1px;
  width: 374px!important;
  padding: 0!important;
  padding-bottom: 10px!important;
  overflow-x: hidden;
  overflow-y: hidden !important;
  margin-right: 8px;
  margin-bottom: 4px;
}

.widget__button{
  position: fixed!important;
  bottom: 10px !important;
  right: 10px !important;
  height: 58px !important;
  width: 58px !important;
  z-index: 11000;
}

.widget__button-text{
  position: fixed!important;
  text-align: center!important;
  bottom: 10px !important;
  right: 20px !important;
  height: auto !important;
  width: auto !important;
  z-index: 11000;
  font-weight: 600 !important;
  font-size: 14px !important;
  margin-bottom: 46px !important;  
  cursor: pointer;
  user-select: none;
  color: #ffff;
  border-radius: 20px!important;
  padding: 10px !important;
  background: #FFA900;
}

#close_widget{
  z-index: 9999;
  position: absolute;
  top: 8px;
  right: 6px;
}

.MuiModal-backdrop{
  background: transparent !important;
}

.MuiDialog-root.MuiModal-root{
  z-index: 12500 !important;
}

.MuiMenu-root.MuiPopover-root.MuiModal-root {
  z-index: 13000 !important;
}

.MuiInputBase-root{
  font-size: 16px !important;
}

.widget-dialog .MuiAutocomplete-popper{
  width: 100% !important;
  display: flex !important;
  justify-content: center !important; 
}

@media screen and (max-width: 375px) {
  .widget-dialog__content{    
    width: auto!important;   
  }
}

.MuiCardMedia-root{
  object-fit: cover !important;
}
