.chatbothr_containerchat{
  overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 15px;
  margin-top: 15px;
}

.chatbothr_containerchat.uip {
  overflow-y: hidden !important;
  user-select: none !important;
}
.chatbothr_containerchat.ui-no_copy {
  user-select: none !important;
}
.chatbothr_containerchat.ui-no_scroll {
  overflow-y: hidden !important;
}
