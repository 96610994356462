.buttonPanel-div{   
    margin-top: 15px;
    text-align: center;
}

.buttonPanel-div__button{
    background: transparent;
    max-width: 40em;
    margin-right: auto;
    font-weight: 400 !important;
    display: block !important;
}

.buttonPanel-div__button__b{
    font-weight: 900 !important;
}

.carousel-description ul{
    margin: 0;
    padding-inline-start: 20px;
}