.videocamerabutton-last-message {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    background: #f0f0f0;
    padding: 0 0.5rem;
    border: 1px solid #eee;
    font-style: italic;
    font-size: 0.8rem;
    border-radius: 4px;

    box-shadow: inset -2px -3px 4px -6px #333;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    max-height: 6rem;
    overflow: hidden;
    overflow-y: auto;
}
