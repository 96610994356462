.MessageChatBot.carousel {
    overflow: hidden;
    position: relative; 
}

.carousel{
    user-select: none;
    max-width: 94vw !important;  
    /*margin-left: -5vw; Problemas en widget*/
}

.carousel-card{    
    border: none;
    border-radius: 20px;
    margin: 1em!important;   
    height: auto !important;
    position: relative;
}

.carousel-card__img{
    object-fit: cover !important;
    object-position: top;
    -webkit-user-drag: none;
    height: 450px;
}

.carousel-title {
    height: auto;   
}

.carousel-description{
    height: auto;   
}

.carousel-stepper{
    position: absolute;
    top: 50%; 
    transform: translateY(-50%);   
    background: transparent!important;
    width: 99.5%;  
    z-index: 99;
}

.carousel-stepper__btn{    
    background: #ffffffc9!important;
    border-radius: 33px!important;
    padding: 10px!important;
    min-width: 0!important;
    color: #000000!important;    
}

.carousel-select__btn{
    height: 3rem!important;
    width: 100%;
    border-radius: 18px;
}

.carousel-description.scroll {
    max-height: 80px; 
    overflow-y: auto;     
}

@media only screen and (max-width: 992px) {    
    .carousel-stepper{ 
        display: none!important;
    } 

    .carousel-card{    
        margin: 0.8em!important;
    }
}

@media only screen and (max-width: 540px) {
    .carousel-card__img{
        max-height: 23vh!important;
    }

    .carousel-card{    
        margin: 0.5em!important;
        height: 250px;
    } 
    .carousel-title {
        height: auto !important;
    }
}

/* .widget-popup .carousel-stepper__btn{ 
    visibility: hidden;
}  */

.widget-dialog .carousel-stepper{ 
    margin-left: 0;
} 

.widget-dialog .carousel-card__img{
    max-height: 23vh!important;
}

.widget-dialog .carousel-card{    
    margin: 0.5em!important;
} 

