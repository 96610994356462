.webcamrecorder-wrapper {
    position: relative;
    max-width: 600px;
    width: auto;
    margin: 0 auto;
}

.webcamrecorder-wrapper video {
    border: 2px solid transparent;
    border-radius: 1rem;
    box-shadow: 0 1px 4px -1px black;
    width: 100%;
}

.webcamrecorder-wrapper .videotag-wrapper {
    display: inline-block;
}

.webcamrecorder-wrapper video {
    width: 100%;
}

.webcamrecorder-wrapper.ar-portrait video {
    max-height: 250px;
    width: auto;
}

.webcamrecorder-wrapper video.webcam-recorder {

}
.webcamrecorder-wrapper video.webcam-player {
    background: gray;
}
.webcamrecorder-wrapper .webcam-error-message {
    background: orangered;
    color: white;
    border-radius: 4px;
    padding: 0.1rem 1rem;
}

.webcamrecorder-wrapper .browser-error-list > li {
    margin-bottom: 1rem;
}

.webcamrecorder-wrapper .webcam-error-message > p {
    font-weight: bold;
}

.recording-dot {
    display: inline-block;
    border-radius: 100%;
    position: absolute;
    border: 1px solid slategray;
    background-color: white;
    box-shadow: 0 0 4px -2px white;
    margin-top: 1rem;
    margin-left: -2rem;
    height: 12px;
    width: 12px;
}

.recording-dot.is-recording {
    background-color: red;

    animation-name: blink;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    -webkit-animation-name:blink;
    -webkit-animation-duration: 1s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
}

@-moz-keyframes blink{
    0% { opacity: 1.0; }
    50% { opacity: 0; }
    100% { opacity: 1.0; }
}

@-webkit-keyframes blink {
    0% { opacity: 1.0; }
    50% { opacity: 0; }
    100% { opacity: 1.0; }
}

@keyframes blink {
    0% { opacity: 1.0; }
    50% { opacity: 0; }
    100% { opacity: 1.0; }
}
